import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: 'measurement',
        loadChildren: () =>
            import('./modules/measurement/measurement.module').then(
                (m) => m.MeasurementModule
            ),
    },
    { path: '', redirectTo: '/measurement', pathMatch: 'full' },
    { path: '**', redirectTo: '/measurement', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
