import { Component } from '@angular/core';
import { ModalService } from '@app/shared/services/modal.service';
import { Router } from '@angular/router';
import { LoaderService } from '@app/shared/services/loader.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(
        public loaderService: LoaderService
        ) {}
    }
